import { useSessionData } from './useSessionData';
import { AccessStatus } from './__types__/SiteInformationQuery';

/**
 * Checks if the user has any licensed access (either admin or use access).
 *
 * @param {AccessStatus | null} accessStatus - The access status of the user.
 * @returns {boolean} - Returns true if the user has licensed admin or use access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
const isLicensed = (accessStatus: AccessStatus | null): boolean => {
	return (
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS
	);
};

/**
 * Checks if the user has licensed admin access.
 *
 * @param {AccessStatus | null} accessStatus - The access status of the user.
 * @returns {boolean} - Returns true if the user has licensed admin access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
const isAdmin = (accessStatus: AccessStatus | null): boolean => {
	return accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;
};

/**
 * Checks if the user has licensed non-admin access (use access).
 *
 * @param {AccessStatus | null} accessStatus - The access status of the user.
 * @returns {boolean} - Returns true if the user has licensed use access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
const isLicensedNonAdmin = (accessStatus: AccessStatus | null): boolean => {
	return accessStatus === AccessStatus.LICENSED_USE_ACCESS;
};

/**
 * Checks if the user is a guest (external collaborator).
 *
 * @param {AccessStatus | null} accessStatus - The access status of the user.
 * @returns {boolean} - Returns true if the user is an external collaborator, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
const isGuest = (accessStatus: AccessStatus | null): boolean => {
	return accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;
};

/**
 * Custom hook to determine if the current user has any licensed access.
 *
 * @returns {boolean} - Returns true if the user has licensed admin or use access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
export const useIsLicensed = (): boolean => {
	const { accessStatus } = useSessionData();
	return isLicensed(accessStatus);
};

/**
 * Custom hook to determine if the current user has licensed admin access.
 *
 * @returns {boolean} - Returns true if the user has licensed admin access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
export const useIsAdmin = (): boolean => {
	const { accessStatus } = useSessionData();
	return isAdmin(accessStatus);
};

/**
 * Custom hook to determine if the current user has licensed non-admin access.
 *
 * @returns {boolean} - Returns true if the user has licensed use access, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
export const useIsLicensedNonAdmin = (): boolean => {
	const { accessStatus } = useSessionData();
	return isLicensedNonAdmin(accessStatus);
};

/**
 * Custom hook to determine if the current user is a guest (external collaborator).
 *
 * @returns {boolean} - Returns true if the user is an external collaborator, otherwise false.
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
export const useIsGuest = (): boolean => {
	const { accessStatus } = useSessionData();
	return isGuest(accessStatus);
};

/**
 * Hook to access all user status checks at once.
 *
 * If new user roles or access types are introduced,
 * consider adding additional checks here to keep
 * the access status evaluation centralized and consistent.
 *
 * @returns {Object} An object containing boolean values for various user status checks:
 * - `isGuest`: True if the user is an external collaborator.
 * - `isLicensed`: True if the user has licensed admin or use access.
 * - `isAdmin`: True if the user has licensed admin access.
 * - `isLicensedNonAdmin`: True if the user has licensed use access.
 *
 * @warning ⚠️ This field is not available in the query preloader in SSR. Do not rely on it to skip preloader queries in SSR.
 */
export const useAccessStatus = () => {
	const { accessStatus } = useSessionData();
	return {
		isGuest: isGuest(accessStatus),
		isLicensed: isLicensed(accessStatus),
		isAdmin: isAdmin(accessStatus),
		isLicensedNonAdmin: isLicensedNonAdmin(accessStatus),
		// Add more checks here if additional access statuses need to be evaluated
	};
};
