import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { isEmojiTitleEnabled } from '@confluence/content-types-utils';

import type { EmojiStatusFieldName, EmojiPropertyKey } from './__types__/EmojiStatusFieldName';

// These property keys are the keys used by the backend to store these properties.
export const EMOJI_TITLE_DRAFT_KEY = 'emoji-title-draft';
export const EMOJI_TITLE_PUBLISHED_KEY = 'emoji-title-published';

// The field names below are used in the result of the EmojiTitleQuery and throughout the emoji-title package.
export const EMOJI_TITLE_DRAFT_FIELD = 'emojiTitleDraft';
export const EMOJI_TITLE_PUBLISHED_FIELD = 'emojiTitlePublished';

// Returns either `"emojiTitleDraft"` or `"emojiTitlePublished"`
// depending on which field should be used for the current page.
export const useEmojiStatusFieldName = (contentType?: string | null): EmojiStatusFieldName => {
	const isLivePage = useIsCurrentPageLive();
	const isWhiteboardEmoji = isEmojiTitleEnabled(contentType);

	// whiteboards and live pages are always published, so return EMOJI_TITLE_PUBLISHED_FIELD
	if (isWhiteboardEmoji || isLivePage) {
		return EMOJI_TITLE_PUBLISHED_FIELD;
	} else {
		return EMOJI_TITLE_DRAFT_FIELD;
	}
};

export const convertEmojiFieldNameToPropertyKey = (
	fieldName: EmojiStatusFieldName,
): EmojiPropertyKey => {
	return fieldName === EMOJI_TITLE_PUBLISHED_FIELD
		? EMOJI_TITLE_PUBLISHED_KEY
		: EMOJI_TITLE_DRAFT_KEY;
};
